/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, ColumnWrap, Title, Button, Image, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Chci se stát vosařem"}>
        <ColumnCover name={"menu"}>
          
          <Column className="--menu --style4 pb--10 pl--40 pr--40 pt--30" menu={true}>
            
            <Menu className="--full pl--06 pr--06" style={{"maxWidth":""}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box">
                
                <Subtitle className="subtitle-box subtitle-box--invert" content={"28. 8. 2021<br>"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="pb--0 pt--0" name={"uvod-2"}>
        </Column>


        <Column className="--full" name={"n8pa7dv2lej"} style={{"backgroundColor":"rgba(72,39,10,1)"}} fullscreen={true}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--2 fs--102 title-box--invert" content={"<span style=\"color: var(--white)\">Svatební videa a fotky<br></span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--30" content={"JEDNOTLIVÉ ODKAZY NÍŽE NEBO YOUTUBE KANÁL POTRAVINOVÝ BALÍČEK"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn5 btn-box--shape4" url={"https://www.youtube.com/playlist?list=PL672stui3XmKvtUFD-BVj37jkTSwYzaV-"} href={"https://www.youtube.com/playlist?list=PL672stui3XmKvtUFD-BVj37jkTSwYzaV-"} style={{"backgroundColor":"rgba(147,22,2,1)"}} content={"Přijít rovnou na POTRAVINOVÝ BALÍČEK YT"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"o8lbraxh4zr"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/c3e5b88ec11d46199430bd50004a19c8_s=860x_.jpg"} url={"https://youtu.be/9eaoaQ_yXRg"} use={"url"} href={"https://youtu.be/9eaoaQ_yXRg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17430/c3e5b88ec11d46199430bd50004a19c8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/c3e5b88ec11d46199430bd50004a19c8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/c3e5b88ec11d46199430bd50004a19c8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/c3e5b88ec11d46199430bd50004a19c8_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/248c7011fb0f4c709719e26a9a3f7926_s=660x_.jpg"} url={"https://youtu.be/1RFvlbdvvK8"} use={"url"} href={"https://youtu.be/1RFvlbdvvK8"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17430/248c7011fb0f4c709719e26a9a3f7926_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/248c7011fb0f4c709719e26a9a3f7926_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/248c7011fb0f4c709719e26a9a3f7926_s=860x_.jpg 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/8b2591202c0c4a8896987dbf3aabd309_s=860x_.jpg"} url={"https://youtu.be/iFpsgvih204"} use={"url"} href={"https://youtu.be/iFpsgvih204"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17430/8b2591202c0c4a8896987dbf3aabd309_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/8b2591202c0c4a8896987dbf3aabd309_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/8b2591202c0c4a8896987dbf3aabd309_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/8b2591202c0c4a8896987dbf3aabd309_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/7686a1492268454e8bddb647dcaf944e_s=860x_.jpg"} url={"https://youtu.be/wcMYFOcsZ_A"} use={"url"} href={"https://youtu.be/wcMYFOcsZ_A"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17430/7686a1492268454e8bddb647dcaf944e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/7686a1492268454e8bddb647dcaf944e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/7686a1492268454e8bddb647dcaf944e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/7686a1492268454e8bddb647dcaf944e_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/80f39aa901944eba80c171a30166ffec_s=860x_.jpg"} url={"https://youtu.be/1y8s7PDLQ0w"} use={"url"} href={"https://youtu.be/1y8s7PDLQ0w"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17430/80f39aa901944eba80c171a30166ffec_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/80f39aa901944eba80c171a30166ffec_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/80f39aa901944eba80c171a30166ffec_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/80f39aa901944eba80c171a30166ffec_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/2e7d99cb3b4e454ba2be244beb3830b1_s=860x_.jpg"} url={"https://youtu.be/ef0cW6bbPHM"} use={"url"} href={"https://youtu.be/ef0cW6bbPHM"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17430/2e7d99cb3b4e454ba2be244beb3830b1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/2e7d99cb3b4e454ba2be244beb3830b1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/2e7d99cb3b4e454ba2be244beb3830b1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/2e7d99cb3b4e454ba2be244beb3830b1_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"zfc770hjjk"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/6f5b41c85c2842f29e274edf4d0fcb4c_s=860x_.jpg"} url={"https://youtu.be/apelAk4A0hE"} use={"url"} href={"https://youtu.be/apelAk4A0hE"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17430/6f5b41c85c2842f29e274edf4d0fcb4c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/6f5b41c85c2842f29e274edf4d0fcb4c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/6f5b41c85c2842f29e274edf4d0fcb4c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/6f5b41c85c2842f29e274edf4d0fcb4c_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/437a07ceeaba498bbdd625a6e9820686_s=860x_.jpg"} url={"https://youtu.be/uCQtqR5mrxk"} use={"url"} href={"https://youtu.be/uCQtqR5mrxk"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17430/437a07ceeaba498bbdd625a6e9820686_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/437a07ceeaba498bbdd625a6e9820686_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/437a07ceeaba498bbdd625a6e9820686_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/437a07ceeaba498bbdd625a6e9820686_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/03fb249242e34c7487d3fba68f1aaad7_s=860x_.jpg"} url={"https://youtu.be/M0GUOEHfqx8"} use={"url"} href={"https://youtu.be/M0GUOEHfqx8"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/17430/03fb249242e34c7487d3fba68f1aaad7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/03fb249242e34c7487d3fba68f1aaad7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/03fb249242e34c7487d3fba68f1aaad7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/03fb249242e34c7487d3fba68f1aaad7_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--08 pt--08" name={"l5ay46bng8i"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image src={"https://cdn.swbpg.com/t/17430/16b5c20e9d87436a8829ba6805791607_s=860x_.jpg"} url={"https://youtu.be/M692Hj5XPuE"} use={"url"} href={"https://youtu.be/M692Hj5XPuE"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":970}} srcSet={"https://cdn.swbpg.com/t/17430/16b5c20e9d87436a8829ba6805791607_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/16b5c20e9d87436a8829ba6805791607_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/16b5c20e9d87436a8829ba6805791607_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/16b5c20e9d87436a8829ba6805791607_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kzqac8ehjh8"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--26" content={"A jako bonus odkaz na třetí svatební dar...&nbsp; &nbsp;<br>Tentokrát to byla pohádka...&nbsp;&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17430/a832b46ccd1c40158aa4af8a5818fbda_s=860x_.jpg"} url={"https://youtu.be/LL0L58YFQzA"} use={"url"} href={"https://youtu.be/LL0L58YFQzA"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":430}} srcSet={"https://cdn.swbpg.com/t/17430/a832b46ccd1c40158aa4af8a5818fbda_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17430/a832b46ccd1c40158aa4af8a5818fbda_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17430/a832b46ccd1c40158aa4af8a5818fbda_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17430/a832b46ccd1c40158aa4af8a5818fbda_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}